import React from "react"

import {
  Footer,
  Header,
  Layout,
  SectionBenefitsWindows,
  SectionOfferWindows,
  SEO,
} from "../components"

const WindowsPage = () => (
  <Layout
    header={<Header />}
    footer={
      <>
        <SectionBenefitsWindows />
        <Footer />
      </>
    }
  >
    <SEO title={'Windows VPS'} />
    <SectionOfferWindows />
  </Layout>
)

export default WindowsPage
